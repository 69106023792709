import { useState } from 'react';

function Cancel() {
  const [confirmed, setConfirmed] = useState(false);

  const bookingId = window.location.pathname.split('/')[2];
  const restaurantId = window.location.pathname.split('/')[3];
  const confirmationCode = window.location.pathname.split('/')[4];

  return (
    <div className="cancel-page">
      <img
        src="https://img.pagecloud.com/b-oXFb10ERWsTUi473qLrwLJYic=/172x0/filters:no_upscale()/rosemarino/gold_white_-_Copy-q0346.png"
        style={{ width: 172, height: 51 }}
      />
      <h1
        style={{
          marginTop: 40,
          marginBottom: 30,
          fontFamily: 'sans-serif',
          fontWeight: 400,
        }}
      >
        Are you sure you'd like to cancel your booking?
      </h1>
      <div className="buttons">
        <div
          className="button"
          onClick={() =>
            cancelBooking(
              bookingId,
              restaurantId,
              confirmationCode,
              setConfirmed
            ).then((r) => console.log(r))
          }
        >
          Yes, cancel my booking
        </div>
        <div
          className="button"
          onClick={() =>
            (document.location.href = 'https://www.rosemarino.co.uk/')
          }
        >
          No, take me back to Rosemarino
        </div>
        <div className={confirmed ? 'confirmed' : 'confirmed hidden'}>
          Booking cancelled.
        </div>
      </div>
    </div>
  );
}

async function cancelBooking(
  bookingId,
  restaurantId,
  confirmationCode,
  setConfirmed
) {
  const requestOptions = {
    method: 'PUT',
  };

  let data = await fetch(
    `https://evening-eyrie-53360.herokuapp.com/api/bookings/public/${bookingId}/${restaurantId}/${confirmationCode}`,
    requestOptions
  );
  if (data.status === 200) setConfirmed(true);
  return data.status;
}

export default Cancel;
